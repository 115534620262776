import React from 'react';
import { graphql } from 'gatsby';
import { injectIntl, Link } from 'gatsby-plugin-intl';

import {famlaw, inhreritance, contract, laborlaw, criminal} from '../assets/icons'; 
import {SEO, Row, RowBut, SecTitle, Button, Heading, Layout_srv, Text ,Title, Icon, Desc, SrvText } from '../components';
import Contact from '../components/contact';
import {Wpclients} from "../components/Images"


const SrvList = ({icon, title, txt, ul,  additionalClasses}) => (
        <Row>
          <Icon>
            <img src={icon} alt={title} />
          </Icon>
          <div>  
            <Title>
             {title}
            </Title>
            <Text>
             {txt}
             <ul>{ul}</ul>
            </Text>
          </div>
        </Row>
) 

SrvList.defaultProps = {
  additionalClasses: '',
}

const Private = ({ intl, data }) => {
  return (
    <Layout_srv>
      <SEO title={intl.formatMessage({ id: 'private.title' })} />
      <div className='header_private'>
        <Wpclients/>
        <div>
        <Heading>{intl.formatMessage({ id: 'private.title' })}</Heading>
        <SrvText className='srvdesc'>{intl.formatMessage({ id: 'private.desc' },{ b: chunks => <b>{chunks}</b>})}</SrvText>
        </div>
      </div>
      <Desc className='srvlist' id="services">
        <SrvList
          icon={famlaw}
          title={intl.formatMessage({ id: 'private.s1.title'})}
          txt={intl.formatMessage({ id: 'private.s1.txt'})}
          ul={intl.formatMessage({ id: 'private.s1.ul'},{li: chunks => <li>{chunks}</li>})}
        />
        <SrvList
          icon={inhreritance}
          title={intl.formatMessage({ id: 'private.s2.title'})}
          txt={intl.formatMessage({ id: 'private.s2.txt'})}
          ul={intl.formatMessage({ id: 'private.s2.ul'},{li: chunks => <li>{chunks}</li>})}
        />
        <SrvList
          icon={contract}
          title={intl.formatMessage({ id: 'private.s3.title'})}
          txt={intl.formatMessage({ id: 'private.s3.txt'})}
          ul={intl.formatMessage({ id: 'private.s3.ul'},{li: chunks => <li>{chunks}</li>})}
        />
        <SrvList
          icon={laborlaw}
          title={intl.formatMessage({ id: 'private.s4.title'})}
          txt={intl.formatMessage({ id: 'private.s4.txt'})}
          ul={intl.formatMessage({ id: 'private.s4.ul'},{li: chunks => <li>{chunks}</li>})}
        />
        <SrvList
          icon={criminal}
          title={intl.formatMessage({ id: 'private.s5.title'})}
          txt={intl.formatMessage({ id: 'private.s5.txt'})}
          ul={intl.formatMessage({ id: 'private.s5.ul'},{li: chunks => <li>{chunks}</li>})}
        />

      </Desc>
      <RowBut>
        <Link to="/">
            <Button>{intl.formatMessage({ id: 'button.gohome' })}</Button>
        </Link>
        <Link to="/bussines">
            <Button>{intl.formatMessage({ id: 'button.bussines' })}</Button>
        </Link>
      </RowBut>
     <Contact></Contact>
    </Layout_srv>
  );
};

export default injectIntl(Private);

